<template>
  <div v-if="topic" class="pt-3">
    <page-breadcrumb :title="`Pronun Topic Detail`" :items="breadcrumbItems" />

    <section class="my-3">
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="topic.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <strong class="h6">Title</strong>
            <!-- <b-form-input type="text" v-model="topic.title" placeholder="Enter your title here" /> -->
            <ui-component :component-data="topic.title_translate" class="border" />
            <strong class="h6 d-block mt-1">Level</strong>
            <div class="d-flex mt-1">
              <b-form-radio
                v-model="topic.level"
                name="topic-level"
                :value="1"
              >Easy</b-form-radio>
              <b-form-radio class="ml-1"
                v-model="topic.level"
                name="topic-level"
                :value="2"
              >Normal</b-form-radio>
              <b-form-radio class="ml-1"
                v-model="topic.level"
                name="topic-level"
                :value="3"
              >Hard</b-form-radio>
            </div>

            <strong class="h6 d-block mt-1">Type</strong>
            <div class="d-flex mt-1">
              <b-form-radio
                v-model="topic.type"
                name="topic-type"
                :value="1"
              >Reading</b-form-radio>
              <b-form-radio class="ml-1"
                v-model="topic.type"
                name="topic-type"
                :value="2"
              >Conversation</b-form-radio>
              <b-form-radio class="ml-1"
                v-model="topic.type"
                name="topic-type"
                :value="3"
              >Content</b-form-radio>
            </div>
            <strong class="h6 d-block mt-1">Content Type</strong>
            <div class="d-flex mt-1" style="width: 150px">
             <b-select v-model="topic.content_type" :options="content_type_options" />
            </div>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <section class="my-3" v-if="topic.type !== 3">
      <h2 class="h2">Sentences</h2>
      <div class="mt-1">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="topic.sentences" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(sentence, sentenceIndex) in topic.sentences" :key="sentence._id || sentence.id"
                class="question-item"
                :title="`Sentence ${sentenceIndex + 1}`" :is-visible="sentenceIndex === topic.sentences.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 sentence-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ sentence.content || `Sentence id: ${sentence.id}` }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeSentence(sentenceIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <sentence-card :sentence-data="sentence" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!topic.sentences.length" class="text-center"><span>No sentence, create them!</span></div>
        </app-collapse>
        <sentence-create-form class="text-center mt-1"
          @created="addSentence"
        />
      </div>
    </section>

    <section class="my-3" v-else>
      <h2 class="h2">Content</h2>
      <ui-component :component-data="topic.content_translate" class="border" />
      <!-- <ui-component-list :component-list="topic.content" /> -->
    </section>

    <action-buttons update-button back-button delete-button
      @update="update" :is-updating="isUpdating" @delete="deleteTopic" :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from '../service'
import SentenceCreateForm from './_components/SentenceCreateForm.vue'
import SentenceCard from './_components/SentenceCard.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    SentenceCreateForm,
    SentenceCard,
    draggable,
  },
  data() {
    return {
      topic: null,
      isUpdating: false,
      isDeleting: false,
      content_type_options:[
        {text: "Word", value: 1},
        {text: "Phrase", value: 2},
        {text: "Sentences", value: 3},
        {text: "Conversations", value: 4},
        {text: "Twisters", value: 5},
      ]
    }
  },
  computed: {
    topicId() {
      return this.$route.params.topic_id
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Pronun Lessons',
          to: { name: 'pronun-lesson-list' },
        },
        {
          text: `Lesson: ${this.lesson.title || this.lesson._id}`,
          to: { name: 'pronun-lesson-detail', params: { lesson_id: this.lesson._id } },
        },
        {
          text: `Step: ${this.topic.step_key || 'NaN'}`,
          to: { name: 'pronun-lesson-detail', params: { lesson_id: this.lesson._id } },
        },
      ]
      items.push({ text: `Pronun Topic:  ${this.topic.title || this.topic._id}`, active: true })
      return items
    },
  },
  created() {
    this.getTopic()
  },
  methods: {
    async getTopic() {
      let { document, lesson_document } = await service.get({ id: this.topicId })
      this.topic = document
      this.lesson = lesson_document
    },
    async update() {
      this.isUpdating = true
      const response = await service.update({ data: JSON.stringify(this.topic) })
      if (response.data.statusCode === 200) {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated topic!' })
      }
      this.isUpdating = false
    },
    async deleteTopic() {
      this.isDeleting = true
      const response = await service.delete({ id: this.topicId })
      if (response.data.statusCode === 200) {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully deleted topic!' })
      }
      this.isDeleting = false
      this.$router.push({ name: 'pronun-lesson-detail', params: { lesson_id: this.lesson._id } })
    },
    async addSentence(sentence) {
      this.topic.sentences.push(sentence)
    },
    async removeSentence(sentenceIndex) {
      this.topic.sentences.splice(sentenceIndex, 1)
    },
  },
}
</script>

<style scoped>
.page-item {
  transition: ease 0.6s
}
</style>
